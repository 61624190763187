<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Saída de Resíduos</h1>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <router-link :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Registrar saída de resíduo</span>
          </router-link>
        </div>
        <div class="col-span-12 xl:col-span-2 mb-1 mt-1">
          <button @click="download" class="transition duration-200 bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            Download
          </button>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-3">
      <div class="col-span-12 xl:col-span-2">
          <label for="obra" class="block text-sm font-medium">Buscar resíduo</label>
          <select v-model="busca" id="nome" name="nome" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="" >Todos</option>
              <option v-for="re in residuos" :key="re" :value="re" >{{ re }}</option>
          </select>
      </div> 
      <div class="col-span-12 xl:col-span-3">
          <label for="obra" class="block text-sm font-medium">Obra</label>
          <select v-model="obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
          </select>
      </div>
      <div class="col-span-12 md:col-span-2">
        <label for="dataInicio" class="block text-sm font-medium">Data Início</label>
        <datepicker v-model="dataInicio" :language="ptBR" name="dataInicio" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-2">
        <label for="dataFim" class="block text-sm font-medium">Data Final</label>
        <datepicker v-model="dataFim" :language="ptBR" name="dataFim" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 xl:col-span-3">
          <label for="mtr" class="block text-sm font-medium">MTR</label>
          <input v-model="mtr" type="number" name="mtr" id="mtr" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      </div>
      <div class="col-span-12 md:col-span-12">
        <div class="float-right">
          <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-80 font-semibold text-center">
            Buscar por filtro
          </button>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 xl:col-span-12 mb-1 mt-1 text-xl">
          Acumulado: <br>
          <span v-for="ac in acumulado" :key="ac._id">
            <b>{{ac._id}}:</b> {{ac.total}} m³ <br>
          </span>
      </div>
      <hr>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} registros cadastrados </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Obra
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Resíduo
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantidade
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    MTR
                  </th>
                  <th @click="updateSort()" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 hover:text-gray-700 uppercase tracking-wider cursor-pointer">
                    Data 
                    (
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 px-0 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 px-0 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                    </svg>
                    )
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Usuário
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <span class="w-4 h-4 rounded-full block" :class="{'bg-red-500': ! item.status || item.status === 'nulo', 'bg-yellow-400': item.status === 'incompleto', 'bg-green-500': item.status === 'completo' }"></span>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.obra ? item.obra.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.nome ? item.nome : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.quantidade ? item.quantidade : '0'}} m³
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.mtr ? item.mtr : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.usuario ? item.usuario.name : ''}}
                      </div>
                    </div>
                  </td>
                 
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">

                    <router-link 
                    :to="`/${route}/form/${item._id}`" 
                    class="text-blue-500 rounded py-1 px-4">
                    Editar
                    </router-link>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                      Excluir
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import moment from 'moment';

export default {
  data() {
    return {
      ptBR: ptBR,
      route: 'residuos',
      list: [],
      busca: '',
      mtr: '',
      dataInicio: moment().startOf('month').toISOString(),
      dataFim: moment().endOf('month').toISOString(),
      obra: null,
      obras: [],
      acumulado: [],
      perPage: 10,
      sort: { data: -1 },
      query: { page: 1, limit: 10, skip: 0  },
      total: 0,
    }
  },
  methods: {
    download() {
      window.open(`${process.env.VUE_APP_API}/v1/residuos/download?token=${this.jwt}&busca=${this.busca}&obra=${this.obra}&dataInicio=${this.dataInicio}&dataFim=${this.dataFim}`);
    },
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { obra: this.obra, busca: this.busca, mtr: this.mtr, dataInicio: this.dataInicio, dataFim: this.dataFim, skip: this.query.skip, limit: this.query.limit, sort: this.sort });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.acumulado = listReq.data.acumulado;
    },
    
    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Removido com sucesso!");
      this.start();
    },

    updateFiltro(){

      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, obra: this.obra, busca: this.busca, mtr: this.mtr, dataInicio: moment(this.dataInicio).toISOString(), dataFim: moment(this.dataFim).toISOString() };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    updateSort() {
      if(this.sort.data === -1) this.sort.data = 1;
      else this.sort.data = -1;
      this.start();
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  components: {
    Datepicker
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;
    if(this.$route.query.mtr) this.mtr = this.$route.query.mtr;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    await this.start();
  },
  async beforeMount() {

    const reqobras = await this.$http.post('/v1/obras/list');
    this.obras = reqobras.data.data;

    if(this.$route.query.obra) this.obra = this.$route.query.obra;
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.dataInicio) this.dataInicio = this.$route.query.dataInicio;
    if(this.$route.query.dataFim) this.dataFim = this.$route.query.dataFim;
    if(this.$route.query.mtr) this.mtr = this.$route.query.mtr;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>